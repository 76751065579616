<template>
  <div>
    <!-- 상단 배너 -->
    <CRow class="mb-3">
      <CCol col="12">
        <img :src="require('@/assets/images/home/banner1.png')" style="width: 100%;" />
      </CCol>
    </CRow>
    <!-- 공지사항, 로그인 -->
    <CRow>
      <CCol col="8">
        <CRow class="mb-3">
          <CCol col="8" style="display: flex; align-items: center; font-weight: bold; font-size: 19px;">공지사항</CCol>
          <!-- <CCol col="4" class="text-right" style="display: flex; align-items: center; justify-content: flex-end; font-size: 15px;">더보기</CCol> -->
        </CRow>
        <CRow class="mx-2" style="border: 1px #707070 solid;">
          <CCol col="12" class="mb-1 py-2" style="border-bottom: 1px #707070 solid;" 
            v-for="item in partnerNotificationList" v-bind:key="item.index"
          >
            <CRow @click="openNotiModal(item)">
              <CCol col="8">{{item.title}}</CCol>
              <CCol col="4" class="text-right">{{$moment(item.date_created).format('YYYY.MM.DD')}}</CCol>          
            </CRow>
          </CCol>
          <!-- <CCol col="12" class="mb-1 py-2" style="border-bottom: 1px #707070 solid;">
            <CRow>
              <CCol col="8">[공지] 수리접수 방법 안내</CCol>
              <CCol col="4" class="text-right">2021.06.20</CCol>          
            </CRow>
          </CCol>
          <CCol col="12" class="mb-1 py-2" style="border-bottom: 1px #707070 solid;">
            <CRow>
              <CCol col="8">[공지] 매장관리 페이지 이용방법 안내</CCol>
              <CCol col="4" class="text-right">2021.06.20</CCol>          
            </CRow>
          </CCol>
          <CCol col="12" class="mb-1 py-2" style="border-bottom: 1px #707070 solid;">
            <CRow>
              <CCol col="8">[공지] 재고관리 이용안내</CCol>
              <CCol col="4" class="text-right">2021.06.20</CCol>          
            </CRow>
          </CCol>
          <CCol col="12" class="mb-1 py-2">
            <CRow>
              <CCol col="8">[공지] 정산관리 이용안내</CCol>
              <CCol col="4" class="text-right">2021.06.20</CCol>
            </CRow>
          </CCol> -->
        </CRow>
      </CCol>
      <!-- 우측 로그인 관리 영역 -->
      <CCol col="4">
        <!-- 비로그인일때 -->
        <div v-if="userIndex == null">
          <CRow class="mb-2">
            <CCol col="12">컴퓨터 안심수리 플랫폼 수리요</CCol>
          </CRow>
          <CRow class="mb-2">
            <CLink style="text-decoration-line: none; color: black;"
              to="/pages/c_login"
            >
              <CCol col="12" class="py-2" style="background-color: #19C1FF; display: flex; align-items: center; justify-content: center; color: #FFFFFF; font-weight: bold;">
                <img :src="require('@/assets/images/home/logo.png')" style="width: 35%;" />
                &nbsp;파트너스 로그인
              </CCol>
            </CLink>
          </CRow>
          <CRow class="mb-2">
            <CCol col="12" class="text-right">
              <span @click="loginPageRoute()">아이디·비밀번호 찾기</span>
              <span> | </span>
              <CLink style="text-decoration-line: none; color: black;"
                to="/pages/c_regist"
              >
                <span>회원가입</span>
              </CLink>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol col="12" class="p-1">
              <CCol col="12" style="border: 1px #707070 solid;">
                <CCol col="12" class="py-4">수리요 파트너스를 통해<br> 스마트한 수리서비스를 시작해보세요!</CCol>
                <CLink style="text-decoration-line: none; color: black;"
                  to="/pages/c_login"
                >
                  <CCol col="12" class="mb-3 py-2" style="display: flex; align-items: center; justify-content: center; color: #19C1FF; font-weight: bold; border: 1px #707070 solid;">
                    <img :src="require('@/assets/images/home/logo2.png')" style="width: 35%;" />
                    &nbsp;파트너스 시작하기
                  </CCol>
                </CLink>
              </CCol>
            </CCol>
          </CRow>
        </div>
        <!-- 로그인일때 -->
        <div v-if="userIndex != null">
          <CRow>
            <CCol col="4" style="display: flex; align-items: center;">
              <div style="width: 45px; height: 45px; border-radius: 23px; overflow: hidden;">
                <img :src="require('@/assets/images/storeManagement_companyRegister/dummy.png')" style="width: 100%;" />
              </div>
            </CCol>
            <CCol col="8" style="padding-left: 0px;">
              <CRow>
                <CCol col="12">
                  <span style="color: #D7E400;">LV.0</span>
                  &nbsp;{{partnerIndexCompanyName}}
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <img :src="require('@/assets/images/storeManagement_inventory/green.png')" style="width: 15px; height: 15px;" />
                  <span>&nbsp;영업중</span>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol col="12" class="p-1">
              <CCol col="12" style="border: 1px #707070 solid; padding: 0px;">
                <CCol col="12" class="py-2 text-center">채팅상담</CCol>
                <div style="text-decoration-line: none; color: #3c4b64;">
                  <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; border-top: 1px #707070 solid; padding: 0px;"
                    v-for="item in chattingList" v-bind:key="item.index"
                    @click="chatting_on(item)"
                  >
                    <CCol col="4" style="display: flex; align-items: center;">
                      <div style="width: 45px; height: 45px; border-radius: 23px; overflow: hidden;">
                        <img :src="require('@/assets/images/storeManagement_companyRegister/dummy.png')" style="width: 100%;" />
                      </div>
                    </CCol>
                    <CCol col="8" style="padding-left: 0px;">
                      <CRow>
                        <CCol col="12">
                          <span style="color: #D7E400;">LV.0</span>
                          &nbsp;
                          {{item.sender_user_index.index == userIndex ? 
                            item.sender_user_index.nickname
                          :
                            item.receiver_user_index.nickname
                          }}
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol col="12">
                          <span>{{item.message}}</span>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CCol>
                </div>
              </CCol>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>

    <CModal
      title="채팅 상담"
      :show.sync="chattingModal"
      size="xl"
    >
      <div :style="customHeight">
        <CRow class="px-5 mb-4" style="overflow: scroll; height: 90%; display: flow-root;"
          id="container" ref="container"
        >
          <div class="mt-2" 
            v-for="item in chattingSelectedData" v-bind:key="item.index"
          >
            <!-- 자신의 채팅 -->
            <!-- if (item.from == route.params.my_index || (item.sender_user_index != undefined && item.sender_user_index.index == route.params.my_index)) { -->
            <div style="width: 100%; min-height: 60px;"
              v-if="(item.from == userIndex || (item.sender_user_index != undefined && item.sender_user_index.index == userIndex))"
            >
              <!-- 날짜 -->
              <div style="width: 100%; height: 20px; text-align: end;">
                <span>2021.07.01 14:01</span>
              </div>
              <!-- 내용 -->
              <div style="width: 100%; min-height: 40px; display: grid; align-items: center; justify-content: end; text-align: end;">
                <span>{{item.message}}</span>
              </div>
            </div>
            <!-- <div style="width: 100%; min-height: 60px; background-color: red;">
              <div style="width: 100%; height: 20px; text-align: end; background-color: yellow;">
                <span>2021.07.01 14:01</span>
              </div>
              <div style="width: 100%; min-height: 40px; display: grid; align-items: center; justify-content: end; text-align: end; background-color: blue;">
                <span>잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.</span>
              </div>
            </div> -->

            <!-- 상대의 채팅 -->
            <!-- if (item.from == route.params.you_index || (item.sender_user_index != undefined && item.sender_user_index.index == route.params.you_index)) { -->
            <div style="width: 100%; min-height: 60px;"
              v-if="(item.from == chattingYouIndex || (item.sender_user_index != undefined && item.sender_user_index.index == chattingYouIndex))"
            >
              <!-- 날짜 -->
              <div style="width: 100%; height: 20px; text-align: start;">
                <span>디엔에스 - 2021.07.01 14:01</span>
              </div>
              <!-- 내용 -->
              <div style="width: 100%; min-height: 40px; display: grid; align-items: center; justify-content: start; text-align: start;">
                <span>{{item.message}}</span>
              </div>
            </div>
            <!-- <div style="width: 100%; min-height: 60px; background-color: red;">
              <div style="width: 100%; height: 20px; text-align: start; background-color: yellow;">
                <span>디엔에스 - 2021.07.01 14:01</span>
              </div>
              <div style="width: 100%; min-height: 40px; display: grid; align-items: center; justify-content: start; text-align: start; background-color: blue;">
                <span>잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.잘 부탁드립니다.</span>
              </div>
            </div> -->
          </div>
        </CRow>

        <CRow>
          <CCol col="10" class="text-center">
            <CInput
              placeholder="채팅 내용을 입력해주세요"
              v-model="message"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
          </CCol>
          <CCol col="2" class="text-center">
            <CButton color="primary" class="px-3" style="width: 100%"
              @click="chattingMessageSend()"
            >전송</CButton>
          </CCol>
        </CRow>
      </div>
    </CModal>

    <CModal
      title="공지사항"
      :show.sync="partnerModalVisible"
      size="xl"
    >
      <div :style="customHeight">
        <CRow class="px-5 mb-4" style="overflow: scroll; height: 90%; display: flow-root;"
          id="container" ref="container"
        >
          <div class="mt-2">
            <div style="width: 100%; min-height: 60px;">
              <div class="mb-5" style="width: 100%; height: 20px; text-align: start;">
                <span style="font-weight: bold;">{{partnerModalData.title}}</span>
              </div>
              <div style="width: 100%; min-height: 40px; display: grid; align-items: center; justify-content: start; text-align: start;">
                <span>{{partnerModalData.contents}}</span>
              </div>
            </div>
          </div>
        </CRow>
      </div>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios';
import PubNubVue from 'pubnub-vue';

const pubnub = PubNubVue.getInstance();
const existingListener = {
  message: function() {
  }
}
// pubnub.addListener({
//   message: function(m) {
//     // handle message
//     var channelName = m.channel; // The channel for which the message belongs
//     var channelGroup = m.subscription; // The channel group or wildcard subscription match (if exists)
//     var pubTT = m.timetoken; // Publish timetoken
//     var msg = m.message; // The Payload
//     var publisher = m.publisher; //The Publisher

//     console.log('전달된 메시지 리스너', m);
//     // const messageTemp = m.message;
    
//   }
// })

export default {
  name: 'c_home',
  data: function () {
    return {
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      userIndex: null,
      partnerIndexCompanyName: '',
      // 공지사항
      partnerNotificationList: [],
      partnerModalVisible: false,
      partnerModalData: {title: '', contents: ''},
      // 채팅
      chattingList: [],
      chattingModal: false,
      windowHeight: window.innerHeight,
      message: '',
      chattingSelectedData: [], // 진행중인 채팅방 대화 내역
      // chattingSelectedDataNew: this.$pnGetMessage('1_2'), // 진행중인 채팅방의 전달된 대화
      chattingSelectedDataNew: [], // 진행중인 채팅방의 전달된 대화
      chattingYouIndex: null,   // 채팅중인 상대의 유저 Index
      channels: null,           // 채팅중인 대화의 채널
    }
  },
  beforeMount() {
    console.log(this.$route.params.userIndex, this.$route.params.partnerIndex);
    console.log(localStorage.getItem('userIndex'), localStorage.getItem('partnerIndex'));
    this.userIndex = localStorage.getItem('userIndex');
    this.partnerIndexCompanyName = localStorage.getItem('partnerIndexCompanyName');

    // 채팅
    const init = async () => {
      const result_asyncUserChattingAllList = await this.asyncUserChattingAllList(this.userIndex);
      console.log('중복 제거 전', result_asyncUserChattingAllList.data.data);
      // 채팅 내역 중에서 중복 제거
      const filterDataArray = [];     // 중복 제거된 실제 데이터를 저장
      const filterIndexArray = [];    // 중복 제거를 위해 상대방의 index값만 보관하여 비교용으로 활용
      result_asyncUserChattingAllList.data.data.forEach((element) => {
        if (element.receiver_user_index.index == this.userIndex) {
          // 받는 사람이 자신일때는 보낸 사람을 비교
          
          if (!filterIndexArray.includes(element.sender_user_index.index)) {
            filterDataArray.push(element);
            filterIndexArray.push(element.sender_user_index.index);
          }
        } else if (element.sender_user_index.index == this.userIndex) {
          // 보낸 사람이 자신일때는 받는 사람을 비교
          
          if (!filterIndexArray.includes(element.receiver_user_index.index)) {
            filterDataArray.push(element);
            filterIndexArray.push(element.receiver_user_index.index);
          }
        }
      })
      console.log('중복 제거 후', filterDataArray);
      console.log('중복 제거 후', filterIndexArray);
      this.chattingList = filterDataArray;
    }
    init();

    const partner_notification_list_init = async () => {
      const result_asyncPartnerNotificationList = await this.asyncPartnerNotificationList();
      console.log('공지사항', result_asyncPartnerNotificationList);
      this.partnerNotificationList = result_asyncPartnerNotificationList.data.data;
    }
    partner_notification_list_init();
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      console.log('화면 크기 변경 : ', newHeight, oldHeight);
    },
    chattingSelectedDataNew(newMessage, oldMessage) {
      console.log('메시지 전달', newMessage, oldMessage);
      if (newMessage.length != 0) {
        this.chattingSelectedData.push(newMessage[newMessage.length - 1].message);
      }
      console.log('추가된 정보', this.chattingSelectedData);

      setTimeout(() => {
        var container = this.$el.querySelector("#container");
        // var container = this.$refs.container;
        container.scrollTop = container.scrollHeight;
      }, 200);
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);

      // console.log(this.$pnGetMessage('1_2'));
    })
    // this.$pnSubscribe({ channels: ['1_2'], withPresence: true });
    // this.$pnSubscribe({ channels: ['1_2'] });

    // pubnub.addListener({
    //   message: function(m) {
    //     // handle message
    //     var channelName = m.channel; // The channel for which the message belongs
    //     var channelGroup = m.subscription; // The channel group or wildcard subscription match (if exists)
    //     var pubTT = m.timetoken; // Publish timetoken
    //     var msg = m.message; // The Payload
    //     var publisher = m.publisher; //The Publisher

    //     console.log('전달된 메시지 리스너', m);
    //     const messageTemp = m.message;
        
    //     this.chattingSelectedData.push(m.message);
    //     console.log('추가됨');
    //   },
    //   presence: function(p) {
    //     // handle presence
    //     var action = p.action; // Can be join, leave, state-change or timeout
    //     var channelName = p.channel; // The channel for which the message belongs
    //     var occupancy = p.occupancy; // No. of users connected with the channel
    //     var state = p.state; // User State
    //     var channelGroup = p.subscription; //  The channel group or wildcard subscription match (if exists)
    //     var publishTime = p.timestamp; // Publish timetoken
    //     var timetoken = p.timetoken;  // Current timetoken
    //     var uuid = p.uuid; // UUIDs of users who are connected with the channel
    //   },
    //   status: function(s) {
    //     var affectedChannelGroups = s.affectedChannelGroups;
    //     var affectedChannels = s.affectedChannels;
    //     var category = s.category;
    //     var operation = s.operation;
    //   }
    // })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    pubnub.removeListener(existingListener);
  },
  computed: {
    customHeight() {
      return {
        'height': `${this.windowHeight / 100 * 80}px`,
        'display': 'grid',
        'align-items': 'end'
      }
    }
  },
  methods: {
    loginPageRoute() {
      this.$router.push({name: 'c_login', params: {}});
    },
    registPageRoute() {
      this.$router.push({name: 'c_regist', params: {}});
    },
    // 채팅
    async asyncUserChattingAllList(my_index) {
      console.log('자신의 index를 통한 채팅 전체 조회 : ', my_index);
      try {
        const result = await axios.get(`https://suriyo.api.togethersoft.co.kr/items/chatting?fields=*.*&filter={"_or": [{"sender_user_index": "${my_index}"},{"receiver_user_index": "${my_index}"}]}`)
        console.log('결과값 : ', result);
        return result
      }
      catch(err) {
        console.log('query 오류 : ', err);
      }
    },
    // 공지사항
    async asyncPartnerNotificationList() {
      console.log('파트너 공지사항 전체 조회');
      try {
        const result = await axios.get(`https://suriyo.api.togethersoft.co.kr/items/partner_notification_list?fields=*`)
        console.log('결과값 : ', result);
        return result
      }
      catch(err) {
        console.log('query 오류 : ', err);
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    presence(ps) {
      console.log('ps값',ps)
    },
    chatting_on(item) {
      const init = async () => {
        // item의 receiver_user_index와 sender_user_index가 자신인지 체크
        let my_index = null;
        let you_index = null;
        if (item.receiver_user_index.index == this.userIndex) {
          my_index = this.userIndex;
          you_index = item.sender_user_index.index;
        } else if (item.sender_user_index.index == this.userIndex) {
          my_index = this.userIndex;
          you_index = item.receiver_user_index.index;
        }

        this.chattingYouIndex = you_index;
        if (my_index > you_index) {
          this.channels = `${you_index}_${my_index}`;
        } else {
          this.channels = `${my_index}_${you_index}`;
        }
        // this.$pnSubscribe({ channels: [this.channels], withPresence: true });
        this.$pnSubscribe({ channels: [this.channels] });
        console.log('채널', this.channels);
        this.chattingSelectedDataNew = this.$pnGetMessage(this.channels);
        // console.log('전달된 메시지', this.$pnGetMessage(this.channels));
        // this.$pnGetPresence(this.channels, this.presence);
        // this.chattingSelectedData = this.$pnGetMessage(this.channels);
        // pubnub.addListener({
        //   message: function(m) {
        //     // handle message
        //     var channelName = m.channel; // The channel for which the message belongs
        //     var channelGroup = m.subscription; // The channel group or wildcard subscription match (if exists)
        //     var pubTT = m.timetoken; // Publish timetoken
        //     var msg = m.message; // The Payload
        //     var publisher = m.publisher; //The Publisher

        //     console.log('전달된 메시지 리스너', m);
        //     // const messageTemp = m.message;
            
        //     this.chattingSelectedData.push(m.message);
        //     console.log('추가됨');
        //   }
        // })

        // 채팅 내역 호출
        const result_asyncUserChattingSelectedList = await this.asyncUserChattingSelectedList(my_index, you_index);
        console.log(result_asyncUserChattingSelectedList.data.data);
        this.chattingSelectedData = result_asyncUserChattingSelectedList.data.data;

        // 스크롤
        setTimeout(() => {
          var container = this.$el.querySelector("#container");
          // var container = this.$refs.container;
          container.scrollTop = container.scrollHeight;
        }, 200);
      }
      init();

      this.chattingModal = true;
    },
    async asyncUserChattingSelectedList(my_index, you_index) {
      console.log('자신과 상대의 index를 통한 채팅내역 전체 조회 : ', my_index, you_index);
      try {
        const result = await axios.get(`https://suriyo.api.togethersoft.co.kr/items/chatting?fields=*.*&limit=-1&filter={"_and": [{"_or": [{"sender_user_index": ${my_index}},{"receiver_user_index": ${my_index}}]},{"_or": [{"sender_user_index": ${you_index}},{"receiver_user_index": ${you_index}}]}]}`)
        console.log('결과값 : ', result);
        return result
      }
      catch(err) {
        console.log('query 오류 : ', err);
      }
    },
    chattingMessageSend() {
      console.log('전송할 정보', this.userIndex, this.message, this.channels);

      this.$pnPublish({channel: this.channels, message: {
        "from": this.userIndex,
        "message": this.message
      }}, (status, response) => {
        console.log(status, response);
        const dataObj = {
          "from": this.userIndex,
          "message": this.message
        }
        this.chattingSelectedData.push(dataObj);

        // 채팅 내용 서버에 저장
        const data = {
            "sender_user_index": this.userIndex,
            "receiver_user_index": this.chattingYouIndex,
            "message": this.message
        }
        axios.post(`https://suriyo.api.togethersoft.co.kr/items/chatting`, data)
        .then((item) => {
            // 전송 메시지 초기화
            this.message = '';
        })
        .catch((err) => {
            console.log(err);
        })
      });
    },
    // 공지사항 모달 열기
    openNotiModal(item) {
      console.log(item);
      this.partnerModalVisible = true;
      this.partnerModalData.title = item.title;
      this.partnerModalData.contents = item.content;
    }
  },
}
</script>
